import Vue from "vue";

export const hideAdmin = {
  update(el, binding, vnode) {
    const tipoUsuario =
      vnode.context.$store.state.datosUsuarioLogueado.tipoUsuario;
    // el might not be present for server-side rendering.
    if (el && tipoUsuario === 1) {
      el.style.display = "none";
    }
  },
};
export const hideSupervisor = {
  update(el, binding, vnode) {
    const tipoUsuario =
      vnode.context.$store.state.datosUsuarioLogueado.tipoUsuario;
    // el might not be present for server-side rendering.
    if (el && tipoUsuario === 2) {
      el.style.display = "none";
    }
  },
};
export const hideContratante = {
  update(el, binding, vnode) {
    const tipoUsuario =
      vnode.context.$store.state.datosUsuarioLogueado.tipoUsuario;
    // el might not be present for server-side rendering.
    if (el && tipoUsuario === 3) {
      el.style.display = "none";
    }
  },
};

Vue.directive("hide-admin", hideAdmin);
Vue.directive("hide-supervisor", hideSupervisor);
Vue.directive("hide-contratante", hideContratante);
